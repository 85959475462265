import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import SpinToWin from 'src/components/SpinToWin'
import SEO from 'src/components/shared/SEO'
import benefitIcon from 'src/assets/img/spin-to-win/03/icon.svg'

const PRODUCT_CONFIG = {
  lookupKey: 'wheel-02',
  slug: 'calming-aid',
  colors: {
    primary: '#29C7C0',
    secondary: '#89DDD9',
    ticker: '#59e1d5'
  },
  aboveTheFold: {
    wheelBaseSVG: null,
    verticalOrientation: false,
    copyOne: 'It’s Your Lucky Day!',
    copyTwo: 'Spin to Win',
    copyThree: 'Gift Cards & Free Products',
    prizeUrl: 'chill-pup-bundle',
    promo: {
      code: 'SPIN20',
      autoApply: true,
      discount: '20%',
      component: null
    }
  },
  benefits: {
    title: 'Like a chill pill, for your pup',
    items: [
      {
        label: 'Helps Your Dog Relax',
        detail:
          'Gently soothe away stress and separation anxiety with the power of natural calming herbs. It’s like a chill pill, for your pup.'
      },
      {
        label: 'Clinically Proven Ingredients',
        detail:
          'Chamomile, passion flower, and valerian root have long been studied for their soothing and calming effects. Melatonin has been clinically proven to aid canine anxiety.'
      },
      {
        label: 'Vet-Recommended',
        detail:
          'Finn supplements were formulated with a veterinary panel and team of canine nutritionists. We use premium, natural ingredients with zero artificial fillers or flavors.'
      },
      {
        label: 'NASC Certified',
        detail:
          "Finn is proudly certified by the National Animal Supplement Council, demonstrating compliance with NASC's rigorous safety, quality, and control standards. We manufacture our products in a cGMP facility, right here in the USA."
      }
    ],
    icon: benefitIcon,
    collapseMinHeight: 100
  }
}

const WheelTwo: React.FC<PageProps> = ({ location }) => {
  const { copyTwo, copyThree } = PRODUCT_CONFIG.aboveTheFold
  const metaTitle = `${copyTwo} - ${copyThree}`
  return (
    <>
      <SEO title={metaTitle} location={location} />
      <Layout location={location}>
        <SpinToWin details={PRODUCT_CONFIG} />
      </Layout>
    </>
  )
}

export default WheelTwo
